<template>
  <div>
    <v-container fluid class="py-8">
      <Notify ref="myNotify" :details="message"></Notify>
      <v-row>
        <v-col cols="12">
          <v-col cols="12">
            <v-card color="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title>{{
                  $t("protfolio.protfolio")
                }}</v-toolbar-title>
                <button
                  v-if="
                    $store.getters['auth/str_per'].indexOf(
                      'cms-protfolio-create'
                    ) > -1
                  "
                  class="py-5 mb-0 ms-auto"
                  @click="add_protfolio()"
                >
                  <v-icon class="custome-btn btn-primary">fas fa-plus</v-icon>
                </button>
              </v-app-bar>
              <Table
                :loading="loading"
                @action="action"
                :btns="btns"
                :headers="header"
                :items="protfolio"
              ></Table>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <Modal :data="modal_data"></Modal>
    </v-container>
  </div>
</template>
<script>
import Table from "../Components/Table.vue";
import Notify from "../Components/New/Notify.vue";
import Modal from "../Components/New/Modal.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("cms");
export default {
  name: "Protfolio-Page",
  components: {
    Table,
    Notify,
    Modal,
  },
  data() {
    return {
      modal_data: {
        size: "500px",
        title: "",
      },
      loading: true,
      image: null,
      message: {
        msg: null,
        type: null,
      },
      btns: [
        {
          type: "icon",
          text: "edit",
          color: "bg-gradient-success",
          icon: "mdi-pencil",
          permission: "cms-protfolio-update",
        },
        {
          type: "icon",
          text: "destroy",
          color: "bg-gradient-danger",
          icon: "mdi-delete",
          permission: "cms-protfolio-delete",
        },
      ],
      header: [
        { text: this.$i18n.t("image"), value: "image", align: "center" },
        { text: this.$i18n.t("cycle.title"), value: "title", align: "center" },
        { text: this.$i18n.t("news.body"), value: "body", align: "center" },
        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
      form_data: {
        title: "",
        image: null,
      },
      form_style: [
        {
          col: "12",
          type: "text",
          label: this.$i18n.t("cycle.title"),
          error: null,
          value_text: "title",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "12",
          type: "textarea",
          rows: 5,
          label: this.$i18n.t("news.body"),
          error: null,
          value_text: "body",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "12",
          action: true,
          type: "file",
          image: true,
          accept: "image/*",
          label: this.$i18n.t("image"),
          error: null,
          value_text: "image",
          type_file: "single",
          rules: [
            (value) =>
              !value ||
              value.size < 2000000 ||
              this.$i18n.t("form.file size should be less than 2 MB"),
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState(["protfolio"]),
  },
  methods: {
    ...mapActions(["getProtfolio", "destroyProtfolio"]),
    get_protfolio() {
      this.getProtfolio().then(
        () => {
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.$store.dispatch("form/setNotify", {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          });
        }
      );
    },
    action(id, name, item) {
      if (name == "edit") {
        this.edit(item);
      } else if (name == "destroy") {
        this.destroy(id);
      }
    },
    add_protfolio() {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title = this.$i18n.t("protfolio.New protfolio");
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.dispatch("form/setData", this.form_style);
      this.$store.commit("form/INFO_CALL", {
        name: "cms/addProtfolio",
      });
    },
    edit(item) {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title =
        this.$i18n.t("protfolio.Edit protfolio") + " # " + item.title;
      this.$store.dispatch("form/setData", this.form_style);
      this.form_data.title = item.title;
      this.form_data.body = item.body;
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.commit("form/INFO_CALL", {
        name: "cms/editProtfolio",
        id: item.id,
      });
    },
    destroy(id) {
      this.$swal({
        title: this.$i18n.t("general.Are you sure?!"),
        text: this.$i18n.t("You won't be able to revert this!"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$i18n.t("general.Yes, delete it!"),
        cancelButtonText: this.$i18n.t("general.No, cancel!"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.destroyProtfolio(id).then(
            (response) => {
              // console.log(response)
              this.$swal.fire(
                this.$i18n.t("general.Deleted!"),
                response.data.message,
                "success"
              );
            },
            (error) => {
              console.log(error);
              this.$swal.fire(
                this.$i18n.t("general.Error"),
                this.$i18n.t("general.There error please try again"),
                "error"
              );
            }
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire(
            this.$i18n.t("general.Cancelled"),
            this.$i18n.t("general.Cancelled Delete"),
            "error"
          );
        }
      });
    },
  },
  mounted() {
    this.get_protfolio();
    document.title = this.$i18n.t("protfolio.protfolio");
  },
};
</script>
